const initialState = {
  notification: {},
  sellerCards: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case "SELLER_NOTIFICATION":
      return {
        ...state,
        notification: action.payload,
      };
    case "SELLER_CARD":
      return {
        ...state,
        sellerCards: action.payload,
      };
    default:
      return state;
  }
}
