import * as Yup from "yup";

export const registrationSchema1 = () =>
  Yup.object().shape({
    first_name: Yup.string()
      .required("First Name is required!")
      .matches(/^[aA-zZ\s]+$/, "First Name can contain only alphabets!"),
    last_name: Yup.string()
      .matches(/^[aA-zZ\s]+$/, "Last Name can contain only alphabets!")
      .required("Last Name is required!"),
    user_name: Yup.string()
      .max(25, "Username can contain only 25 characters!")
      .required("Username is required!")
      .test(
        "type",
        'Usernames cannot contain "@".Please Choose Another Username.',
        (value) => {
          return value && !value.includes("@");
        },
      ),
    password: Yup.string()
      .trim()
      .min(8, "Password contain at least 8 characters!")
      .required("Password is required!"),
    confirm_password: Yup.string()
      .trim()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Password confirmation is required!"),
    email: Yup.string()
      .email("Please enter a valid email!")
      .required("Email is required!"),
    mobile_number: Yup.string()
      .trim()
      .min(11, "Please enter a valid phone number")
      .required(
        "Please enter your mobile phone number with country code first -- US country code is 1.",
      ),
    address1: Yup.string().required("Address is required"),
    city: Yup.string().required("Please Enter City"),
    state: Yup.string().required("Please Select State"),
    zip_code: Yup.string().required("Please Enter Zipcode"),
    //   .min(5, "Invalid Zipcode")
    //   .max(12, "invalid zipcode"),
    country: Yup.string().required("Please Select Country"),
  });
export const registrationSchema2 = () =>
  Yup.object().shape({
    // termsCondition: Yup.bool().required(
    //   "Please accept the terms and condition"
    // ),
    termsCondition: Yup.bool().oneOf([true], "PLEASE ACCEPT TO REGISTER"),
    goldenRule: Yup.bool().oneOf([true], "PLEASE ACCEPT TO REGISTER"),
    goldenAckw: Yup.bool().oneOf([true], "PLEASE ACCEPT TO REGISTER"),
    send_grid: Yup.bool().oneOf([true], "PLEASE ACCEPT TO REGISTER"),
  });

export const loginSchema = () =>
  Yup.object().shape({
    userName: Yup.string().required("Email or Username is Required"),
    password: Yup.string().required("Password is Required"),
  });

export const passwordauctionSchema = () =>
  Yup.object().shape({
    auction_password: Yup.string().required("Password is Required"),
  });

export const forgotPasswordSchema = () =>
  Yup.object().shape({
    email: Yup.string().required("Email is Required"),
  });
export const ForgotchangePasswordSchema = () =>
  Yup.object().shape({
    new_password: Yup.string()
      .trim()
      .min(8, "New password must contain at least 8 characters!")
      .required("Password confirmation is required!"),
    confirm_password: Yup.string()
      .trim()
      .oneOf(
        [Yup.ref("new_password"), null],
        "New password and confirm password should match!",
      )
      .required("Password confirmation is required!"),
  });
export const changePasswordSchema = Yup.object().shape({
  current_password: Yup.string()
    .trim()
    .required("Current password is required!"),
  new_password: Yup.string()
    .trim()
    .min(8, "New password must contain at least 8 characters!")
    .required("Password confirmation is required!"),
  confirm_new_password: Yup.string()
    .trim()
    .oneOf(
      [Yup.ref("new_password"), null],
      "New password and confirm password should match!",
    )
    .required("Password confirmation is required!"),
});
export const changeUserNameSchema = Yup.object().shape({
  user_name: Yup.string()
    .max(25, "Username contains only 25 characters!")
    .required("Username is required!")
    .test(
      "type",
      'Usernames cannot contain "@".Please Choose Another Username.',
      (value) => {
        return value && !value.includes("@");
      },
    ),
  // confirm_user_name: Yup.string()
  //   .max(20, "UserName contains only 20 characters!")
  //   .required("Confirm UserName is required!"),
  confirm_user_name: Yup.string()
    .oneOf(
      [Yup.ref("user_name"), null],
      "username and confirm username should match!",
    )
    .required("Username confirmation is required!"),
});
export const profileschema = () =>
  Yup.object().shape({
    first_name: Yup.string()
      .required("First Name is required!")
      .matches(/^[aA-zZ\s]+$/, "First Name can contain only alphabets!"),
    last_name: Yup.string()
      .matches(/^[aA-zZ\s]+$/, "Last Name can contain only alphabets!")
      .required("Last Name is required!"),
    // user_name: Yup.string().required("User Name is required!"),
    // password: Yup.string()
    //   .min(8, "Password contain at least 8 characters!")
    //   .required("Password is required!"),
    // confirm_password: Yup.string().oneOf(
    //   [Yup.ref("password"), null],
    //   "Passwords must match"
    // ),
    // email: Yup.string()
    //   .email("Please enter a valid email!")
    //   .required("Email is required!"),
    mobile_number: Yup.string()
      .trim()
      .min(11, "Please enter a valid phone number")
      .required(
        "Please enter your mobile phone number with country code first -- US country code is 1.",
      ),
    // address1: Yup.string().required("Address is required"),
    // city: Yup.string().required("Please Enter City"),
    // state: Yup.string().required("Please Select State"),
    // zip_code: Yup.string()
    //   .required("Please Enter Zipcode")
    //   .min(5, "Invalid Zipcode")
    //   .max(12, "invalid zipcode"),
    // country: Yup.string().required("Please Select Country"),
  });

export const otpscheme = Yup.object().shape({
  mobile_number: Yup.string().required("Mobile number is required!"),
  bddw_sms_consent: Yup.bool().oneOf([true], "Please Select Checkbox"),
});

export const cardSchema = Yup.object().shape({
  // card_name: Yup.string().required('Card name is required!'),
  card_number: Yup.string()
    .min(18, "Invalid card number!")
    .max(19, "Invalid card number!")
    .required("Card number is required!"),
  card_name: Yup.string()
    .required("Card Name is required")
    .matches(/^[aA-zZ\s]+$/, "Card Name contains only alphabets!"),
  //cvv_copy:Yup.string(),
  cvv: Yup.string()
    .min(3, "Invalid cvv!")
    .max(4, "Invalid cvv!")
    .required("CVV is required!"),
  exp_date: Yup.string().required("Expiry date is required!"),
  address1: Yup.string().required("Address is required"),
  city: Yup.string().required("Please Enter City"),
  state: Yup.string().required("Please Select State"),
  zip_code: Yup.string().required("Please Enter Zipcode"),
  //   .min(5, "Invalid Zipcode")
  //   .max(12, "invalid zipcode"),
  country: Yup.string().required("Please Select Country"),
});
