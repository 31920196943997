import axios from "axios";
import { isAuthenticated } from "./Auth";

export const request = async (
  method,
  url,
  data = {},
  userToken = false,
  urlPrefix = 1,
) => {
  let token = isAuthenticated() ? isAuthenticated() : "";
  var headers = {
    Authorization: userToken ? `Bearer ${token}` : "",
    domain: "bddw-staging.ecommerce.auction",
  };
  var fullURL;
  if (urlPrefix === 1) {
    fullURL = process.env.REACT_APP_DOMAIN + "mobileapi" + url;
  } else if (urlPrefix === 2) {
    headers = undefined;
    data.account_id = process.env.REACT_APP_AUCTION_PAY_ID;
    fullURL = process.env.REACT_APP_AUCTION_PAY_URL + url;
  } else if (urlPrefix === 3) {
    fullURL = process.env.REACT_APP_DOMAIN + url;
  } else {
    fullURL = process.env.REACT_APP_DOMAIN + url;
  }
  try {
    const res = await axios({ method, url: fullURL, data, headers });
    // console.log(res)
    if (res) {
      return res;
    }
  } catch (error) {
    return error;
  }
};
