import React, { useState } from "react";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import "./SearchInput.css";
import { Button } from "@material-ui/core";
import { FaSearch } from "react-icons/fa";

export default function SearchInput(props) {
  const [value, setValue] = useState("");
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        props.onChange(value);
      }}
    >
      <div className="inputSearch">
        <InputBase
          className={props.className}
          placeholder={
            props?.placeholder ? props.placeholder : "Search auction....."
          }
          inputProps={{ "aria-label": "search" }}
          value={value}
          onChange={(e) => {
            setValue(e.target.value.toUpperCase());
          }}
        />
        <div className="">
          <Button onClick={(e) => props.onChange(value)}>
            <FaSearch />
          </Button>
        </div>
      </div>
    </form>
  );
}
