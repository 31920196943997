import io from "socket.io-client";
import moment from "moment";
import { isAuthenticated } from "../Utils/Auth";
import jwtDecode from "jwt-decode";
const socket = io(process.env.REACT_APP_DOMAIN, { transports: ["websocket"] });
const socket_record = io("https://auction-io.ecommerce.auction", {
  transports: ["websocket"],
});
let serverTime = new Date();
let socketConnect = 1;

socket.on("connect", function () {
  console.log("Socket Connected");
});

function bidAddtime(cb) {
  socket.on("bidAddtime", (data) => {
    cb(data);
  });
}

function realClosedUpdates(cb) {
  socket.on("realclosedupdates", (data) => {
    cb(data);
  });
}

function refreshbuyer(cb) {
  socket.on("refreshbuyer", (data) => {
    cb(data);
  });
}
function refreshbuyerOff() {
  socket.off("refreshbuyer");
}
function bid_extended(cb) {
  socket.on("bid_extended", (data) => {
    cb(data);
  });
}
function bidding_flag_update(cb) {
  socket.on("bddw_bidding_flag_update", (data) => {
    cb(data);
  });
}
function bid_extendedOff() {
  socket.off("bid_extended");
}

socket.on("sliservertime", (data) => {
  if (moment(data.dTime).isValid()) {
    serverTime = new Date(data.dTime);
  }
  var user = isAuthenticated() ? jwtDecode(isAuthenticated()) : null;
  clearTimeout(socket.pingTimeout);
  socket.pingTimeout = setTimeout(function () {
    console.log("Socket Heartbeat Stop");
    var socket_data = {
      serverTime: serverTime,
      user_id: user ? user.id : 0,
      type: 1,
    };
    socket_record.emit("bddw_socket_record", socket_data);
    socketConnect = 0;
  }, 5000);
  return false;
});

socket.on("disconnect", function () {
  console.log("Socket Disconnected");
  var user = isAuthenticated() ? jwtDecode(isAuthenticated()) : null;
  var socket_data = {
    serverTime: serverTime,
    user_id: user ? user.id : 0,
    type: 2,
  };
  socket_record.emit("bddw_socket_record", socket_data);
  socketConnect = 0;
});
export {
  bidAddtime,
  realClosedUpdates,
  refreshbuyer,
  socket,
  refreshbuyerOff,
  bid_extended,
  bid_extendedOff,
  bidding_flag_update,
  serverTime,
  socketConnect,
};
