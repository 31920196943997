export function addTimer(payload) {
  return {
    type: "ADD_TIMER",
    payload,
  };
}

export const SET_TIMER_STATE = "SET_TIMER_STATE";

export const setTimerState = (id, isExpired) => ({
  type: SET_TIMER_STATE,
  payload: { id, isExpired },
});
