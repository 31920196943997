import React from "react";
import TextField from "@material-ui/core/TextField";
import MuiPhoneInput from "material-ui-phone-number";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
const CustomInput = (props) => {
  const shrink = props.shrink ? props.shrink.toString() : "false";
  return (
    <div className="customInput">
      <label style={props.style}>
        {props.label}
        {props.required ? <b>{props.required}</b> : ""}
      </label>
      {props.type_input == "phone" ? (
        <PhoneInput
          placeholder="Phone Number"
          value={props.value ? props.value : "+1"}
          country={"us"}
          autoFormat={true}
          countryCodeEditable={false}
          //disableDropdown={true}
          className="CountryInput"
          onChange={(val, country) => {
            props.onChange(val, country);
          }}
        />
      ) : (
        // <MuiPhoneInput
        //   masks={{ us: "+.. (...) ..-..-..", at: "+.. (....) ...-...." }}
        //   value={
        //     props.value
        //       ? props.value
        //       : global.country_code
        //       ? global.country_code
        //       : "+1"
        //   }
        //   autoFocus={props.autoFocus}
        //   name={props.name}
        //   onChange={(val) => {
        //     props.onChange(val);
        //   }}
        //   InputProps={{
        //     inputProps: props.inputProps,
        //     startAdornment: props.startAdornment,
        //     shrink: shrink,
        //   }}
        //   id={props.id}
        //   type={props.type}
        //   size={props.size}
        //   disabled={props.disabled}
        //   variant="outlined"
        //   placeholder={props.placeholder}
        //   error={props.error}
        //   helperText={props.helperText}
        //   countryCodeEditable={props.countryCodeEditable}
        //   disableCountryCode={props.disableCountryCode}
        // />
        <TextField
          value={props.value}
          disabled={props.disabled}
          autoFocus={props.autoFocus}
          name={props.name}
          onChange={props.onChange || props.onChange}
          onBlur={props.onBlur || props.onBlur}
          className={props.className}
          InputProps={{
            inputProps: props.inputProps,
            startAdornment: props.startAdornment,
            endAdornment: props.endAdornment,
            shrink: false,
          }}
          id={props.id}
          // label={props.label}
          type={props.type}
          size={props.size}
          // disabled={props.disabled}
          variant="outlined"
          placeholder={props.placeholder}
          error={props.error}
          helperText={props.helperText}
          autoComplete="new-password"
        />
      )}
    </div>
  );
};

export default CustomInput;
