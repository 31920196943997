import React from "react";
import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import Routes from "./routes";
import { SnackbarProvider } from "notistack";
import "react-image-lightbox/style.css";
import "react-virtualized/styles.css";
import SEO from "./Utils/SEO";

function App() {
  return (
    <div className="App">
      <SnackbarProvider
        maxSnack={4}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <SEO url={window.location.href} />
        <Router>
          <Routes />
        </Router>
      </SnackbarProvider>
    </div>
  );
}

export default App;
