import Timer from "../../../../../components/atoms/Timer";
import { SET_TIMER_STATE } from "../../Actions/timer";

const initialState = {
  timer: localStorage.getItem("prodtimer") || "",
  timers: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case "ADD_TIMER":
      return {
        ...state,
        return: action.payload,
      };
    case SET_TIMER_STATE:
      const existingTimerIndex = state.timers.findIndex(
        (timer) => timer.id === action.payload.id,
      );

      if (existingTimerIndex !== -1) {
        return {
          ...state,
          timers: state.timers.map((timer) =>
            timer.id === action.payload.id
              ? { ...timer, isExpired: action.payload.isExpired }
              : timer,
          ),
        };
      } else {
        return {
          ...state,
          timers: [
            ...state.timers,
            { id: action.payload.id, isExpired: action.payload.isExpired },
          ],
        };
      }

    default:
      return state;
  }
}
