import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { isAuthenticated } from "../../../Utils/Auth";
import jwtDecode from "jwt-decode";
const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      width: "100%",
      height: "60px",
    },
  },
}));

const ColorButton = withStyles((theme) => ({
  root: {
    color: "#000",
    borderRadius: "0px",
    boxShadow: "0px 4px 12px rgba(var(--secColor), 20%)",
    backgroundColor: "var(--secColor)",
    border: "1px solid var(--primColor)",
    fontWeight: "400",
    transition: "200ms all ease-in-out",
    "&:hover": {
      backgroundColor: "var(--primColor)",
      border: "1px solid var(--primColor)",
      color: "#fff",
      opacity: "0.9",
      boxShadow: "0px 4px 12px rgba(var(--primColor), 20%)",
    },
  },
}))(Button);

const SecondaryButton = (props) => {
  const classes = useStyles();
  const { admin, isemp } = isAuthenticated()
    ? jwtDecode(isAuthenticated())
    : {};

  return (
    <div className={`${classes.root} ${props.btnSize} secButton`}>
      <ColorButton
        variant="outlined"
        color="primary"
        onClick={props.onClick}
        className={props.className}
        style={props.style}
        type={props.type}
        disabled={
          parseInt(admin) == 1 &&
          parseInt(isemp) == 1 &&
          props.dont_need_disable != "need"
            ? true
            : props.disabled
        }
      >
        {props.iconLeft}
        {props.label}
        {props.children}
      </ColorButton>
    </div>
  );
};

export default SecondaryButton;
