import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import PrimaryButton from "../../components/atoms/PrimaryButton";
import "./Landing.css";
import "../demo.css";
import ContainerLayout from "../../components/molecules/ContainerLayout";
import { handleRedirectInternal } from "../../Utils";
import { request } from "../../Utils/Request";
import { isAuthenticated } from "../../Utils/Auth";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { CSSTransition, TransitionGroup } from "react-transition-group";

const content =
  "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore.";

export default function Landing(props) {
  const history = useHistory();
  const [landPageId, setLandPageId] = useState("");
  const [place_holder, setPlaceholder] = useState("");
  const [lande_page_head_text, setLandheadpagetext] = useState("");
  const [land_page_enter_text, setLandpageentertext] = useState([]);
  const [images, setImages] = useState([]);
  const [imageIndexes, setImageIndexes] = useState([0, 1, 2, 3]); // Initial image indexes
  const [quadrantIndex, setQuadrantIndex] = useState(-1); // Current quadrant index
  const [finalImages, setFinalImages] = useState(null);
  let globalIndex = 0;
  useEffect(() => {
    setImages(
      global?.navigation_details?.slider_images ||
        // ? shuffleArray(global.navigation_details.slider_images)
        [],
    );
    setLandPageId(
      global?.navigation_details?.landingpage_link
        ? global.navigation_details.landingpage_link
        : "",
    );
    setPlaceholder(
      global?.navigation_details?.place_holder
        ? global.navigation_details.place_holder
        : content,
    );
    setLandpageentertext(
      global?.navigation_details?.landingpagelink_text
        ? global.navigation_details.landingpagelink_text
        : [],
    );
    setLandheadpagetext(
      global?.navigation_details?.landingpagetop_text
        ? global.navigation_details.landingpagetop_text
        : "",
    );
  }, []);

  // function shuffleArray(array) {
  //   const shuffledArray = [...array];

  //   for (let i = shuffledArray.length - 1; i > 0; i--) {
  //     const randomIndex = Math.floor(Math.random() * (i + 1));
  //     [shuffledArray[i], shuffledArray[randomIndex]] = [
  //       shuffledArray[randomIndex],
  //       shuffledArray[i],
  //     ];
  //   }

  //   return shuffledArray;
  // }

  const getRandomImageIndex = (excludeIndexes) => {
    const availableIndexes = images
      .map((_, index) => index)
      .filter((index) => !excludeIndexes.includes(index));
    const randomIndex =
      availableIndexes[Math.floor(Math.random() * availableIndexes.length)];
    return randomIndex || 4;
  };

  // function randomNumberFunc(min, max) {
  //   return Math.floor(Math.random() * (max - min + 1) + min);
  // }

  function replaceDuplicatesWithRandom(arr) {
    const seenNumbers = new Set();

    if (arr && arr.length > 0) {
      const getRandomUniqueNumber = () => {
        let randomNumber;
        do {
          randomNumber = Math.floor(Math.random() * (images?.length - 1));
        } while (seenNumbers.has(randomNumber));
        seenNumbers.add(randomNumber);
        return randomNumber;
      };

      const result = arr.reduce((acc, num, i) => {
        const isLastDuplicate =
          arr.lastIndexOf(num) === i && arr.indexOf(num) !== i;

        acc.push(isLastDuplicate ? getRandomUniqueNumber() : num);
        return acc;
      }, []);

      return result;
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setImageIndexes((prevIndexes) => {
        const nextIndex = (quadrantIndex + 1) % 4; // Calculate the next quadrant index
        const updatedIndexes = [...prevIndexes];
        updatedIndexes[nextIndex] = getRandomImageIndex(updatedIndexes);
        // randomNumberFunc(0, images?.length - 1);

        setQuadrantIndex(nextIndex);
        let newImg = updatedIndexes;
        return replaceDuplicatesWithRandom(newImg);
      });
    }, 2500); // 1.5 seconds delay for each quadrant

    return () => clearInterval(interval);
  }, [quadrantIndex]);

  useEffect(() => {
    if (images && images.length > 0) {
      setFinalImages(
        imageIndexes.map(
          (i) => global.navigation_details?.image_path + images[i]?.images,
        ),
      );
    }
  }, [images, imageIndexes]);

  const check_auction_passwors = async (landPageId) => {
    if (landPageId) {
      if (
        landPageId.toString().includes("www.") ||
        landPageId.toString().includes("http://") ||
        landPageId.toString().includes("https://")
      ) {
        // handleRedirectInternal(history, landPageId);
        window.location.href = landPageId;
      } else {
        try {
          const res = await request(
            "post",
            "/check_auction_password",
            {
              auctionid: landPageId.toString().includes("/")
                ? landPageId.split("/")[1]
                : "",
              auction_password: "",
            },
            true,
          );
          if (res.data.success) {
            var auction_title = landPageId.toString().includes("/")
              ? landPageId.split("/")[1]
              : "url-error";
            history.push({ pathname: "/search/" + auction_title });
          } else {
            var auction_title = landPageId.toString().includes("/")
              ? landPageId.split("/")[1]
              : "url-error";
            history.push({
              pathname: "/check_auction_password/" + auction_title,
            });
          }
        } catch (err) {
          console.log(err, "==> error from get dtls");
        }
      }
    } else {
      handleRedirectInternal(history, "search/Lot-");
    }
  };

  const [active, setActive] = useState(false);

  return (
    <div className="container">
      <ContainerLayout>
        <div className="landing">
          <div className="landingBtns">
            {land_page_enter_text.map((data) => (
              <PrimaryButton
                label={data.label}
                dont_need_disable={"need"}
                onClick={() => check_auction_passwors(data.path)}
              />
            ))}
          </div>
          <p
            className="p landingTopTitle"
            dangerouslySetInnerHTML={{
              __html: lande_page_head_text,
            }}
          />

          <div className="landingBanner">
            {finalImages
              // ?.slice(globalIndex, globalIndex + 4)
              ?.map((data, index) => (
                <>
                  <div className="bannerImageContainer">
                    {/* {console.log(finalImagesOne, "finalImagesOne")} */}
                    <TransitionGroup>
                      <CSSTransition
                        key={data}
                        timeout={1000}
                        classNames="fade"
                      >
                        <div className="image-group">
                          <img src={data} alt="Slideshow" className="image" />
                        </div>
                      </CSSTransition>
                    </TransitionGroup>
                  </div>
                </>
              ))}
          </div>

          <p
            className="p"
            dangerouslySetInnerHTML={{
              __html: place_holder,
            }}
          />

          {/* <div>
            WANT TO BE NOTIFIED BY SMS TEXT FOR ALL AUCTIONS? OPTIN{" "}
            <Link
              to={isAuthenticated() ? "/dashboard?move=1" : "/login?move_to=1"}
            >
              HERE
            </Link>
            . (MUST BE A BDDW AUCTIONS ACCOUNT HOLDER, STANDARD RATES APPLY).
          </div> */}
        </div>
      </ContainerLayout>
    </div>
  );
}
