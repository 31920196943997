import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { NavLink } from "react-router-dom";
import { useHistory } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { handleRedirectInternal } from "../../../Utils";
import { isAuthenticated } from "../../../Utils/Auth";
import { request } from "../../../Utils/Request";
import { useSnackbar } from "notistack";
import DialogPop from "../DialogPop";
import { useFormik } from "formik";
import { otpscheme } from "../../../Utils/Validator";
import CustomInput from "./CustomInput";
import CustomCheckbox from "./CustomCheckbox";
import PrimaryButton from "../PrimaryButton";
import OtpInput from "react-otp-input";
import { Badge } from "@material-ui/core";
const CustomDropdown = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const history = useHistory();
  const [otppopup, setOtppopup] = useState(false);
  const [otpsec, setOtpsec] = useState(60);
  const [finalotppopup, setFinalotpopup] = useState(false);
  const [profile, setProfile] = useState({});
  const [otpverification, setOtpverification] = useState(false);
  const [decryptcode, setDecryptcode] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const formik = useFormik({
    initialValues: profile,
    enableReinitialize: true,
    validationSchema: otpscheme,
    onSubmit: async (values) => {
      var send_data = {
        user_phone: values.mobile_number,
        user_email: values.email,
        user_id: profile.id,
      };
      const res_sms = await request(
        "post",
        "/bddw_checkEmailAndSMSCode",
        send_data,
      );
      if (res_sms.data.status) {
        setDecryptcode(res_sms.data.encrypt_sms_code);
        enqueueSnackbar("SMS send to your given number.please check!", {
          variant: "success",
          preventDuplicate: true,
        });
        setOtppopup(false);
        setFinalotpopup(true);
        setOtpsec(60);
      } else {
        enqueueSnackbar(res_sms.data.status_text, {
          variant: "error",
          preventDuplicate: true,
        });
      }
    },
  });

  useEffect(() => {
    if (parseInt(otpsec) != 0 && finalotppopup) {
      var interval = setInterval(() => {
        setOtpsec(parseInt(otpsec) - 1);
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [otpsec, finalotppopup]);

  const updateuserdetails = async (val) => {
    const formdata = new FormData();
    formdata.set("first_name", profile.first_name);
    formdata.set("last_name", profile.last_name);
    formdata.set("username", profile.user_name);
    formdata.set("email", profile.email);
    formdata.set(
      "phone",
      val ? profile.mobile_number : formik.values.mobile_number,
    );
    formdata.set("address1", profile.address1);
    formdata.set("address2", profile.address2);
    formdata.set("country", profile.country);
    formdata.set("city", profile.city);
    formdata.set("state", profile.state);
    formdata.set("zip", profile.zip_code);
    formdata.set("bddw_sms_consent", val ? 2 : 1);

    const customkeys = {
      instagram: profile.instagram,
      goldenRule: profile.goldenRule,
      goldenAckw: profile.goldenAckw,
    };
    formdata.set("company_name", JSON.stringify(customkeys));
    const res = await request("post", "/updateUserProfile", formdata, true);
    if (res.data.status === "yes") {
      if (val == 1) {
        return enqueueSnackbar("Profile updated successfully!", {
          variant: "success",
          preventDuplicate: true,
        });
      }
    }
  };

  const getUserdetails = async () => {
    try {
      const res = await request("post", "/getUserProfileDetails", {}, true);
      if (res.data.success === "yes") {
        var user = res.data.data;
        const { instagram, goldenAckw, goldenRule } = user.company_name
          ? JSON.parse(user.company_name)
          : {};
        setProfile({
          first_name: user.first_name,
          last_name: user.last_name,
          user_name: user.username,
          email: user.email,
          mobile_number: !user.phone.includes("+")
            ? "+" + user.phone
            : user.phone,
          address1: user.address1,
          address2: user.address2,
          city: user.city,
          state: user.state,
          zip_code: user.zip,
          country: user.country,
          customer_id: user.card_paymentid,
          instagram: instagram,
          goldenAckw: goldenAckw,
          goldenRule: goldenRule,
          bddw_sms_consent: parseInt(user.bddw_sms_consent) == 0 ? false : true,
          id: user.id,
          otpnumber: "",
        });
        // if (parseInt(user.bddw_sms_consent) == 0) {
        //   setOtppopup(true)
        // }
      } else {
        if (
          res.data?.failedreason ===
          "Your account status is not active. Please contact BDDW Admin."
        ) {
          localStorage.removeItem("userToken");
          localStorage.removeItem("Userdetails");
          enqueueSnackbar("YOUR ACCOUNT DEACTIVATED BY ADMIN NOW!", {
            variant: "error",
            preventDuplicate: true,
          });
          window.location.href = "/login";
        }
      }
      //viewAllCard(user.card_paymentid);
    } catch (error) {
      console.log(error, "this is from the user details");
    }
  };

  useEffect(() => {
    if (props.profile) {
      setProfile({ ...props.profile });
    } else {
      setProfile({});
    }
  }, [props.profile]);
  const Logout = (e) => {
    localStorage.removeItem("userToken");
    localStorage.removeItem("Userdetails");
    const prefix = "auction_password_";
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key.startsWith(prefix)) {
        localStorage.removeItem(key);
        i--;
      }
    }
    handleRedirectInternal(history, "login");
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const user = isAuthenticated() ? jwt_decode(isAuthenticated()) : "";
  // const first_name=(jwt_decode(isAuthenticated())[0].first_name)
  // const last_name=(jwt_decode(isAuthenticated())[0].last_name)

  const confirmotp = async (e) => {
    if (formik.values.otpnumber == "") {
      enqueueSnackbar("Please Enter Otp Number!", {
        variant: "error",
        preventDuplicate: true,
      });
      return false;
    }

    if (formik.values.otpnumber.length < 4) {
      enqueueSnackbar("Please Enter Otp Number!", {
        variant: "error",
        preventDuplicate: true,
      });
      return false;
    }
    const res_sms_check = await request("post", "/bddw_checkSMSCode", {
      user_phone: formik.values.mobile_number,
      bddw_sms_consent: formik.values.sms_list ? 1 : 0,
      sms_code: formik.values.otpnumber,
      encrypt_sms_code: decryptcode,
      user_id: profile.id,
      user_email: formik.values.email,
    });
    if (res_sms_check.data.status) {
      setFinalotpopup(false);
      setOtpverification(true);
      enqueueSnackbar("Opt-in for BDDW AUCTIONS SMS alerts is complete.", {
        variant: "success",
        preventDuplicate: true,
      });
      updateuserdetails(0);
    } else {
      enqueueSnackbar(res_sms_check.data.status_text, {
        variant: "error",
        preventDuplicate: true,
      });
    }
  };

  const resendotp = async (e) => {
    const res_sms_resend = await request("post", "/bddw_resendSMSCode", {
      user_phone: formik.values.mobile_number,
      user_email: formik.values.email,
      encrypt_sms_code: decryptcode,
      user_id: profile.id,
    });
    if (res_sms_resend.data.status) {
      setOtpsec(60);
      enqueueSnackbar("SMS send to your given number.please check!", {
        variant: "success",
        preventDuplicate: true,
      });
    } else {
      enqueueSnackbar(res_sms_resend.data.status_text, {
        variant: "error",
        preventDuplicate: true,
      });
    }
  };

  return (
    <div className="menuItem">
      {props.type == "desktop" ? (
        <>
          <Button
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleClick}
          >
            {parseInt(props.unpaidcount) > 0 ? (
              <Badge
                badgeContent={props.unpaidcount}
                color="secondary"
                // color="primary"
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                <span className={props.className}>{props.icon}</span>
              </Badge>
            ) : (
              ""
            )}
            {profile.user_name}
            <i className={props.className}>{props.arrow}</i>
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => {
                handleRedirectInternal(history, "dashboard");
                handleClose();
              }}
            >
              My Account
            </MenuItem>
            <MenuItem onClick={(e) => Logout(e)}>Logout</MenuItem>
            {/* <NavLink to='/dashboard'>
        </NavLink>
        <NavLink>
        </NavLink>
        <NavLink>
        </NavLink> */}
          </Menu>
        </>
      ) : (
        ""
      )}
      <DialogPop
        //onClose={(e) => setOtppopup(false)}
        open={otppopup}
        title="SMS VERIFICATION"
        nobutton={1}
      >
        <div className="dialogBody">
          <form className="regFrom my-3 pb-4">
            <div className="row row-cols-1">
              <div className="col-md-12 col-sm-12 col-xs-12">
                {console.log(
                  "ppppppppppppppppppppppppppppppppppppppppppp",
                  formik.values.mobile_number,
                )}
                {/* <CustomInput
              variant="outlined"
              placeholder="Mobile Number"
              label="MOBILE NUMBER"
              type_input="phone"
              type="phone"
              required="*"
              name="mobile_number"
              value={formik.values.mobile_number}
              onChange={(val) => formik.setFieldValue("mobile_number", val)}
              onBlur={formik.handleBlur}
            />
            {formik.errors.mobile_number && formik.touched.mobile_number ? (
              <div className="error-feedback">
                {formik.errors.mobile_number}
              </div>
            ) : null} */}
                {/* <div className="checkboxLine stepFstcheckBoXline">
              <CustomCheckbox
                label="BDDW AUCTIONS allows account holders to opt-in for SMS alerts and notifications. By opting in to these alerts you agree to receive auction notifications from BDDW AUCTIONS such as upcoming auctions, impending auction closings, and more. Be advised that at this time we are not sending Auction Lot bidding updates. You may opt out at any time."
                labelPlacement="end"
                value="sms_list"
                name="sms_list"
                checked={formik.values.sms_list}
                onchange={(e) =>
                  formik.setFieldValue("sms_list", !formik.values.sms_list)
                }
                onBlur={formik.handleBlur}
              />
            </div> */}
              </div>
            </div>
            <div className="checkboxLine">
              <CustomCheckbox
                label="Send me auction updates via text. Get updates about our auctions via SMS text. Standard rates apply. You may opt out at any time."
                labelPlacement="end"
                value="sms_list"
                name="sms_list"
                checked={formik.values.sms_list}
                onchange={(e) => {
                  formik.setFieldValue(
                    "bddw_sms_consent",
                    !formik.values.bddw_sms_consent,
                  );
                }}
                onBlur={formik.handleBlur}
              />
            </div>
            {formik.errors.bddw_sms_consent &&
            formik.touched.bddw_sms_consent ? (
              <div className="error-feedback">
                {formik.errors.bddw_sms_consent}
              </div>
            ) : null}
            <div className="flexOtpBtns">
              <PrimaryButton label={"Submit"} onClick={formik.handleSubmit} />
              <PrimaryButton
                label={"I DON'T WANT TO RECEIVE SMS UPDATES"}
                onClick={(e) => {
                  setOtppopup(false);
                  updateuserdetails(1);
                }}
              />
            </div>
          </form>
        </div>
      </DialogPop>

      <DialogPop
        //onClose={(e) => setOtppopup(false)}
        open={finalotppopup}
        title="SMS VERIFICATION"
        nobutton={1}
      >
        <div className="dialogBody">
          <form className="regFrom my-3 pb-4">
            <div className="container">
              <p className="mb-2 text-center">
                You opted in to recieve SMS text for phone number
                <b className="pl-1">{formik.values.mobile_number}.</b>
              </p>
              <p className="text-center">
                Please enter the one-time-passcode sent to that number.
              </p>
              <div className="d-flex justify-content-center">
                <div className="otpFieldSet">
                  <OtpInput
                    value={formik.values.otpnumber}
                    onChange={(val) => {
                      formik.setFieldValue("otpnumber", val);
                    }}
                    className="asdasd"
                    numInputs={4}
                    renderSeparator={<span>-</span>}
                    // inputStyle={{ width: "3em", height: "5em" }}
                    renderInput={(props) => <input {...props} />}
                  />
                </div>
              </div>
              {otpsec != 0 ? (
                <>
                  <p className="brfresentBtn">
                    RESEND BUTTON WILL ENABLE AFTER {otpsec} SEC.
                  </p>
                  <div className="flexOtpBtns">
                    <PrimaryButton
                      label={"Submit"}
                      onClick={(e) => confirmotp(e)}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="flexOtpBtns">
                    <PrimaryButton
                      label={"Submit"}
                      onClick={(e) => confirmotp(e)}
                    />
                  </div>
                  <div className="flexOtpBtns">
                    <PrimaryButton
                      label={"Resend"}
                      onClick={(e) => resendotp(e)}
                    />
                  </div>
                </>
              )}
            </div>
          </form>
        </div>
      </DialogPop>
    </div>
  );
};
export default CustomDropdown;
