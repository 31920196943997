import React from "react";
import { Helmet } from "react-helmet";

const SEO = ({ title, description, keywords, url, image, ...rest }) => {
  return (
    <Helmet>
      <title>{`${title ? title + " | " : ""} BDDW Auctions`}</title>
      <meta
        name="title"
        content={`${title ? title + " | " : ""} BDDW Auctions`}
      />
      <meta name="description" content={description}></meta>
      <meta name="keywords" content={keywords}></meta>

      <meta property="og:url" content={url || window.location.href} />
      <meta
        property="og:title"
        content={`${title ? title + " | " : ""} BDDW Auctions`}
      />
      <meta property="og:description" content={description} />
      {rest.children}
    </Helmet>
  );
};

export default SEO;
