import React from "react";
import { withStyles } from "@material-ui/core/styles";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { ListItem } from "@material-ui/core";

const GreenCheckbox = withStyles({
  root: {
    color: "var(--primColor)",
    "&$checked": {
      color: "var(--primColor)",
    },
    MuiFormControlLabelRoot: {
      marginBottom: 0,
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const CustomCheckbox = (props) => {
  return (
    <FormGroup row>
      <FormControlLabel
        control={
          <GreenCheckbox
            name={props.name}
            checked={props.checked}
            value={props.value}
            onChange={props.onchange ? (e) => props.onchange(e) : null}
            disabled={props.disabled}
          />
        }
        label={<ListItem button>{props.label}</ListItem>}
      />
    </FormGroup>
  );
};

export default CustomCheckbox;
