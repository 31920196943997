import React from "react";
import { Container, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  mdContainer: {
    maxWidth: "1166px",
  },
  xlContainer: {
    maxWidth: "1280px",
  },
}));

export default function ContainerLayout(props) {
  const classes = useStyles();

  return (
    <div className={`customContainer ${props.className}`}>{props.children}</div>
  );
}
